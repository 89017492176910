import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Text
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import { changeUsername } from '../services/apiService'

const ChangeUsername: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()
  const [initialValues, setInitialValues] = useState({ newUsername: '' })

  useEffect(() => {
    if (auth?.username) {
      setInitialValues({ newUsername: auth.username })
    }
  }, [auth])

  // Validation schema using Yup
  const validationSchema = Yup.object({
    newUsername: Yup.string()
      .min(3, t('Username must be at least 3 characters'))
      .required(t('Username is required'))
  })

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      // 비동기 작업 또는 API 호출이 필요할 경우 이곳에서 처리
      const { data: res } = await changeUsername({
        username: values.newUsername
      })
      if (res.statusCode === 200) {
        toast({
          title: t('Success'),
          description: t('Your username has been changed successfully.'),
          status: 'success',
          duration: 1500,
          isClosable: true
        })
      }
    } catch (err: any) {
      toast({
        title: t('Fail'),
        description: t(err.response?.data?.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={5}>
          <Heading as="h4" size="md">
            {t('Change username')}
          </Heading>
          <Text mt={0.5}>{t('You can change your username.')}</Text>
        </Box>

        <Box
          maxW="md"
          mx="auto"
          mt={6}
          p={6}
          borderWidth={1}
          borderRadius="lg"
          bg={'white'}
        >
          {initialValues.newUsername && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {() => (
                <Form>
                  <VStack spacing={4}>
                    <FormControl id="newUsername">
                      <FormLabel>{t('New Username')}</FormLabel>
                      <Field name="newUsername">
                        {({ field }: { field: any }) => (
                          <Input type="text" {...field} />
                        )}
                      </Field>
                      <ErrorMessage name="newUsername">
                        {(msg) => <Text color="red.500">{msg}</Text>}
                      </ErrorMessage>
                    </FormControl>
                    <Button
                      type="submit"
                      bg="#33D1C9"
                      color="#ffffff"
                      size="lg"
                      _hover={{ bg: '#2BA8A3' }}
                      width="full"
                    >
                      {t('Change username')}
                    </Button>
                  </VStack>
                </Form>
              )}
            </Formik>
          )}
        </Box>
      </DefaultLayout>
    </>
  )
}

export default ChangeUsername
