import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Text
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'

const ShopDetail: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box></Box>
      </DefaultLayout>
    </>
  )
}

export default ShopDetail
