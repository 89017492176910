import { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import {
  Button,
  Grid,
  Box,
  Text,
  Heading,
  useToast,
  useClipboard,
  Input,
  Spinner,
  Flex
} from '@chakra-ui/react'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import QRCode from 'react-qr-code'

const Marketing = () => {
  const { t } = useTranslation()
  const [auth] = useRecoilState(authState)
  const toast = useToast()

  const inviteUrl = `https://waveproject.io/?inviteCode=${auth.code}`

  // Clipboard hook for copying the invite URL
  const { hasCopied, onCopy } = useClipboard(inviteUrl)

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box textAlign="center" mt={10}>
          <Heading as="h2" size="lg" mb={6}>
            {t('Share Your Invite Link')}
          </Heading>

          {/* QR Code */}
          <Box mb={6}>
            <Flex justifyContent="center">
              <QRCode value={inviteUrl} size={200} />
            </Flex>
          </Box>

          {/* Invite URL */}
          <Box mb={6}>
            <Input
              value={inviteUrl}
              isReadOnly
              size="lg"
              textAlign="center"
              bg="gray.100"
            />
          </Box>

          {/* Copy Button */}
          <Button onClick={onCopy} colorScheme="teal" size="lg" mb={6}>
            {hasCopied ? t('Copied!') : t('Copy Invite Link')}
          </Button>
        </Box>
      </DefaultLayout>
    </>
  )
}

export default Marketing
