import {
  Container,
  Box,
  Flex,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Image,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  VStack,
  Text,
  Spacer,
  Divider
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import { FaGlobe, FaBars } from 'react-icons/fa'
import { useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { useRecoilState } from 'recoil'
import { authState } from '../../atoms/auth'
import { signOut } from '../../services/apiService'
import BigNumber from 'bignumber.js'
import { rateState } from '../../atoms/rate'

interface AppBarProps {
  showHamburgerMenu: boolean
}

const AppBar = ({ showHamburgerMenu }: AppBarProps) => {
  const { t, i18n } = useTranslation()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef(null)
  const navigate = useNavigate()
  const [auth] = useRecoilState(authState)
  const [rate, setRate] = useRecoilState(rateState)

  const handleLanguageChange = (language: string) => {
    i18n.changeLanguage(language)
  }

  return (
    <>
      <Box bg="#33D1C9" p={4} color="white">
        <Container maxW="1200px">
          <Flex justify="space-between" align="center">
            <Box
              cursor="pointer"
              onClick={() => {
                if (showHamburgerMenu) {
                  navigate('/app')
                } else {
                  navigate('/')
                }
              }}
            >
              <Image
                src="/images/logo/logo_w.png"
                alt="Wave Project"
                sx={{
                  width: '100px',
                  height: '25px'
                }}
              />
            </Box>
            <Flex align="center">
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Change language"
                  icon={<FaGlobe />}
                  color="white"
                  colorScheme="#33D1C9"
                  mr={2}
                />
                <MenuList>
                  <MenuItem
                    onClick={() => handleLanguageChange('en')}
                    color="black"
                  >
                    <Image
                      src="/images/flags/en.png"
                      alt="English"
                      boxSize="20px"
                      mr="8px"
                    />
                    English
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageChange('ko')}
                    color="black"
                  >
                    <Image
                      src="/images/flags/ko.png"
                      alt="Korean"
                      boxSize="20px"
                      mr="8px"
                    />
                    한국어
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageChange('ja')}
                    color="black"
                  >
                    <Image
                      src="/images/flags/ja.png"
                      alt="Japanese"
                      boxSize="20px"
                      mr="8px"
                    />
                    日本語
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageChange('zh')}
                    color="black"
                  >
                    <Image
                      src="/images/flags/zh.png"
                      alt="Chinese"
                      boxSize="20px"
                      mr="8px"
                    />
                    中文 (Chinese)
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageChange('th')}
                    color="black"
                  >
                    <Image
                      src="/images/flags/th.png"
                      alt="Thai"
                      boxSize="20px"
                      mr="8px"
                    />
                    ไทย (Thai)
                  </MenuItem>
                  <MenuItem
                    onClick={() => handleLanguageChange('vi')}
                    color="black"
                  >
                    <Image
                      src="/images/flags/vi.png"
                      alt="Vietnamese"
                      boxSize="20px"
                      mr="8px"
                    />
                    Tiếng Việt (Vietnamese)
                  </MenuItem>
                </MenuList>
              </Menu>

              {/* 햄버거 메뉴 - Drawer로 변경 */}
              {showHamburgerMenu && (
                <>
                  <IconButton
                    ref={btnRef}
                    aria-label="Options"
                    icon={<FaBars />}
                    color="white"
                    colorScheme="#33D1C9"
                    onClick={onOpen}
                  />
                  <Drawer
                    isOpen={isOpen}
                    placement="right"
                    onClose={onClose}
                    finalFocusRef={btnRef}
                  >
                    <DrawerOverlay />
                    <DrawerContent>
                      <DrawerCloseButton />
                      <DrawerHeader>
                        <Image
                          src="/images/logo/logo2.png"
                          alt="Wave Project"
                          sx={{
                            width: '100px',
                            height: '25px'
                          }}
                        />
                      </DrawerHeader>
                      <DrawerBody>
                        <Box>
                          <Box mb={1}>
                            <Text fontSize="md" as="b">
                              {t('Username')}
                            </Text>
                            <Text fontSize="sm">{auth.username}</Text>
                          </Box>

                          <Box mb={1}>
                            <Text fontSize="md" as="b">
                              {t('Wallet Address')}
                            </Text>
                            <Text fontSize="sm">{auth.walletAddress}</Text>
                          </Box>

                          <Box mb={1}>
                            <Text fontSize="md" as="b">
                              {t('Plan Participation Amount')}
                            </Text>
                            <Text fontSize="sm">
                              {new BigNumber(auth.stakingBalance).toFixed(2, 1)}{' '}
                              {'USDT'}
                            </Text>
                          </Box>

                          <Box mb={1}>
                            <Text fontSize="md" as="b">
                              {t('Total Assets')}
                            </Text>
                            <Text fontSize="sm">
                              {new BigNumber(auth.tokenBalance).toFixed(2, 1)}{' '}
                              {'WVP'}
                            </Text>
                            <Text fontSize="md">
                              ≈{' '}
                              {new BigNumber(auth.tokenBalance)
                                .multipliedBy(rate)
                                .toFixed(2, 1)}{' '}
                              {'USDT'}
                            </Text>
                          </Box>
                        </Box>

                        <Box py={3}>
                          <Divider />
                        </Box>

                        <VStack align="stretch" height="100%">
                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/app')
                              onClose()
                            }}
                          >
                            {t('Home')}
                          </Button>
                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/deposit')
                              onClose()
                            }}
                          >
                            {t('Staking')}
                          </Button>
                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/withdrawal')
                              onClose()
                            }}
                          >
                            {t('Withdrawal')}
                          </Button>
                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/team')
                              onClose()
                            }}
                          >
                            {t('Team')}
                          </Button>
                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/history')
                              onClose()
                            }}
                          >
                            {t('History')}
                          </Button>
                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/marketing')
                              onClose()
                            }}
                          >
                            {t('Invite Friends')}
                          </Button>
                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/notice')
                              onClose()
                            }}
                          >
                            {t('Notice')}
                          </Button>

                          <Box py={3}>
                            <Divider />
                          </Box>

                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/password')
                              onClose()
                            }}
                          >
                            {t('Change password')}
                          </Button>

                          <Button
                            variant="outline"
                            onClick={() => {
                              navigate('/username')
                              onClose()
                            }}
                          >
                            {t('Change username')}
                          </Button>

                          <Box py={3}>
                            <Divider />
                          </Box>

                          <Button
                            variant="outline"
                            colorScheme="red"
                            onClick={async () => {
                              await signOut()
                              onClose()
                              navigate('/')
                            }}
                            sx={{ mb: 3 }}
                          >
                            {t('Sign Out')}
                          </Button>
                        </VStack>
                      </DrawerBody>
                    </DrawerContent>
                  </Drawer>
                </>
              )}
            </Flex>
          </Flex>
        </Container>
      </Box>

      {showHamburgerMenu && (
        <Box bg="#000000" p={4} color="white">
          <Container maxW="1200px">
            <Box textAlign={'center'}>
              <Text fontSize="xs">{auth.walletAddress}</Text>
            </Box>
          </Container>
        </Box>
      )}
    </>
  )
}

export default AppBar
