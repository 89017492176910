// frontend/src/pages/Home.tsx
import { useEffect, useRef, useState } from 'react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import {
  useToast,
  Box,
  Text,
  Flex,
  VStack,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  Icon,
  Button,
  Grid,
  Switch,
  FormControl,
  FormLabel,
  Progress,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Image
} from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import { getUser, manualJoin, toggleAutoStaking } from '../services/apiService'
import CheckAuth from '../components/layouts/CheckAuth'
import BigNumber from 'bignumber.js'
import { useUpdateAuthState } from '../utils/authUtils'
import { rateState } from '../atoms/rate'
import Announcements from '../components/Announcements'
import NoticeDialog from '../components/NoticeDialog'
import Menu from '../components/Menu'

const App = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()
  const [auth, setAuth] = useRecoilState(authState)
  const [rate, setRate] = useRecoilState(rateState)
  const [data, setData] = useState({
    participationAmount: 100.0,
    dailyAllowance: 1.0,
    maxDailyAllowance: 1000000.0,
    dailyReferralReward: 0.0,
    dailyVolumeReward: 0.0,
    dailyWaveReward: 0.0
  })

  const [isOpen, setIsOpen] = useState(false)
  const onClose = () => setIsOpen(false)
  const cancelRef = useRef(null)

  const updateAuthState = useUpdateAuthState()

  const handleManualParticipation = () => {
    setIsOpen(true) // Open the modal when the button is clicked
  }

  const handleConfirm = async () => {
    setIsOpen(false)
    try {
      const { data: res } = await manualJoin()
      if (res.statusCode === 200) {
        // Update the auth state
        await updateAuthState()

        // Logic for Re-participation goes here
        toast({
          title: t('Re-participation'),
          description: t('All assets have been staked'),
          status: 'success',
          duration: 1500,
          isClosable: true
        })
      }
    } catch (err: any) {
      // toast
      toast({
        title: t('Fail'),
        description: t(err.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  // Add Token to MetaMask
  const addTokenToMetaMask = async () => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: '0xdcb2130caca8f8d1ca746ab88a7a5e92014d8782',
              symbol: 'WVP',
              decimals: 8,
              image: 'https://info.waveproject.io/img/logo/logo_200_200.png'
            }
          }
        })
        toast({
          title: t('Success'),
          description: t('Token added to Wallet App successfully!'),
          status: 'success',
          duration: 1500,
          isClosable: true
        })
      } catch (error) {
        console.error('Error adding token:', error)
        toast({
          title: t('Error'),
          description: t('Failed to add token to Wallet App.'),
          status: 'error',
          duration: 1500,
          isClosable: true
        })
      }
    } else {
      toast({
        title: t('MetaMask not detected'),
        description: t('Please install Wallet App to add tokens.'),
        status: 'warning',
        duration: 1500,
        isClosable: true
      })
    }
  }

  return (
    <>
      <CheckAuth />
      <NoticeDialog />
      <DefaultLayout>
        {/* Confirmation Modal */}
        <AlertDialog
          isOpen={isOpen}
          leastDestructiveRef={cancelRef}
          onClose={onClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t('Re-participation')}
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text>{t('All your assets will be staked.')}</Text>
                <Text>
                  {t('Do you agree to participate with all your assets?')}
                </Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onClose}>
                  {t('Cancel')}
                </Button>
                <Button colorScheme="teal" onClick={handleConfirm} ml={3}>
                  {t('Agree')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <Announcements />

        <Box maxW="md" mx="auto" py={2}>
          <VStack spacing={4} align="stretch">
            <StatBox2
              label={t('Plan Participation Amount')}
              percent={auth.stakingPercent}
              value1={`${new BigNumber(auth.stakingBalance).toFixed(
                0,
                1
              )} USDT`}
              value2={`${new BigNumber(auth.stakingBalance)
                .dividedBy(100)
                .multipliedBy(auth.stakingPercent)
                .toFixed(2, 1)} USDT ≈ ${new BigNumber(auth.stakingBalance)
                .dividedBy(100)
                .multipliedBy(auth.stakingPercent)
                .dividedBy(rate)
                .toFixed(2, 1)} WVP`}
              value3={`${new BigNumber(auth.limitReward).toFormat()} USDT`}
              tooltipContent={t(
                'The personal reward and maximum daily payout vary depending on the number of tokens you are participating with.'
              )}
              handleManualParticipation={handleManualParticipation}
            />

            {/* <Menu /> */}
            <Box
              p={4}
              shadow="md"
              borderRadius="md"
              bg="white"
              backgroundImage="url('/images/wavenation.png')"
              backgroundSize="cover" // 이미지가 박스를 꽉 채우도록 설정
              backgroundPosition="center" // 이미지를 가운데에 맞춤
              height="100px" // 필요한 높이로 설정
              onClick={() => navigate('/lucky')}
            ></Box>

            <Box>
              <Box
                p={4}
                shadow="md"
                borderWidth="1px"
                borderRadius="md"
                bg="white"
              >
                <Stat>
                  <StatLabel>
                    <Flex align="center" justify="space-between">
                      <Text>{t('Total Assets')}</Text>
                      <Button
                        size="sm"
                        _hover={{ bg: '#2BA8A3' }}
                        // 버튼의 크기를 조정하여 더 적합하게 만듭니다
                        width="auto"
                        onClick={addTokenToMetaMask}
                      >
                        {t('WVP Wallet Creation')}
                      </Button>
                    </Flex>
                  </StatLabel>
                  <StatNumber>
                    {new BigNumber(auth.tokenBalance).toFixed(2, 1)} {'WVP'}
                  </StatNumber>
                  <Box>
                    ≈{' '}
                    {new BigNumber(auth.tokenBalance)
                      .multipliedBy(rate)
                      .toFixed(2, 1)}{' '}
                    {'USDT'}
                  </Box>
                </Stat>

                <Box mt={2}>
                  <Grid templateColumns="repeat(1, 1fr)" gap={4}>
                    <Button
                      bg="#33D1C9"
                      color="#ffffff"
                      size="sm"
                      _hover={{ bg: '#2BA8A3' }}
                      width="full"
                      onClick={() => {
                        navigate('/withdrawal')
                      }}
                    >
                      {t('Withdrawal')}
                    </Button>
                  </Grid>
                </Box>
              </Box>
            </Box>

            <StatBox
              label={t("Today's Team Reward")}
              value={`${new BigNumber(auth.rewardTeamSum).toFixed(2, 1)} WVP`}
              tooltipContent={t(
                'This is the team reward earned from members who have referred you.'
              )}
            />
            <StatBox
              label={t("Today's Volume Reward")}
              value={`${new BigNumber(auth.rewardVolumeSum).toFixed(2, 1)} WVP`}
              tooltipContent={t(
                'This is a bonus reward added to the team reward based on your level.'
              )}
            />
            <StatBox
              label={t("Today's Wave Reward")}
              value={`${new BigNumber(auth.rewardWaveSum).toFixed(2, 1)} WVP`}
              tooltipContent={t(
                'This is a reward calculated and paid once a day based on a portion of the total interest under your level.'
              )}
            />
          </VStack>
        </Box>
      </DefaultLayout>
    </>
  )
}

const StatBox = ({ label, value, tooltipContent }: any) => {
  const [rate, setRate] = useRecoilState(rateState)
  const [showTooltip, setShowTooltip] = useState(false)

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  return (
    <Box p={4} shadow="md" borderWidth="1px" borderRadius="md" bg="white">
      <Stat>
        <StatLabel>
          <Flex align="center">
            {label}
            {tooltipContent && (
              <Box ml={2} onClick={handleToggleTooltip}>
                <Icon
                  as={InfoIcon}
                  color="gray.500"
                  w={4}
                  h={4}
                  cursor="pointer"
                />
                {showTooltip && (
                  <Box
                    mt={2}
                    p={2}
                    bg="gray.700"
                    color="white"
                    borderRadius="md"
                    position="absolute"
                    zIndex={1}
                    maxW="xs"
                  >
                    {tooltipContent}
                  </Box>
                )}
              </Box>
            )}
          </Flex>
        </StatLabel>
        <StatNumber>{value}</StatNumber>
      </Stat>
    </Box>
  )
}

const StatBox2 = ({
  label,
  percent,
  value1,
  value2,
  value3,
  tooltipContent,
  handleManualParticipation
}: any) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()
  const [showTooltip, setShowTooltip] = useState(false)
  const [auth, setAuth] = useRecoilState(authState)
  const [rate, setRate] = useRecoilState(rateState)
  const updateAuthState = useUpdateAuthState()

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  const handleToggleSwitch = async () => {
    try {
      const { data: res } = await toggleAutoStaking({
        toggle: auth.autoStaking ? false : true
      })
      if (res.statusCode === 200) {
        await updateAuthState()

        if (!auth.autoStaking) {
          toast({
            title: t('Auto Participation'),
            description: t(
              'the total assets will automatically participate in staking.'
            ),
            status: 'success',
            duration: 1500,
            isClosable: true
          })
        } else {
          toast({
            title: `${t('Auto Participation')} ${' '} ${t('Cancel')}`,
            description: t('Auto Participation has been disabled.'),
            status: 'success',
            duration: 1500,
            isClosable: true
          })
        }
      }
    } catch (error: any) {
      toast({
        title: t('Fail'),
        description: t(error.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  useEffect(() => {
    // ChannelIO 스크립트가 로드되었는지 확인하고 초기화
    const initializeChannelIO = () => {
      if (window.ChannelIO) {
        window.ChannelIO('boot', {
          pluginKey: 'dae5d1b1-020b-4258-9dae-b642dab03add'
        })
      } else {
        console.error('ChannelIO script not loaded')
      }
    }

    // ChannelIO가 로드될 때까지 대기
    const channelInterval = setInterval(() => {
      if (window.ChannelIO) {
        clearInterval(channelInterval)
        initializeChannelIO()
      }
    }, 100)

    return () => {
      clearInterval(channelInterval)
      // 채널톡 해제
      if (window.ChannelIO) {
        window.ChannelIO('shutdown')
      }
    }
  }, [])

  return (
    <Box
      p={4}
      shadow="md"
      borderWidth="1px"
      borderRadius="md"
      bg="white"
      position="relative"
    >
      <Stat>
        <StatLabel>
          <Flex align="center" justifyContent="space-between">
            <Flex>
              {label}
              {tooltipContent && (
                <Box ml={2} onClick={handleToggleTooltip}>
                  <Icon
                    as={InfoIcon}
                    color="gray.500"
                    w={4}
                    h={4}
                    cursor="pointer"
                  />
                  {showTooltip && (
                    <Box
                      mt={2}
                      p={2}
                      bg="gray.700"
                      color="white"
                      borderRadius="md"
                      position="absolute"
                      zIndex={1}
                      maxW="xs"
                    >
                      {tooltipContent}
                    </Box>
                  )}
                </Box>
              )}
            </Flex>

            {/* Image positioned in the top-right corner */}
            <Box position="absolute" top="0" right="0" zIndex={2}>
              <Image
                src={`/images/level/${auth?.userLevel}vp.png`}
                alt="level"
                width="38px"
                height="45px"
              />
            </Box>
          </Flex>
        </StatLabel>
        <StatNumber>{value1}</StatNumber>
      </Stat>
      <Text fontSize="xs">
        {t('Personal Reward')} ({percent}%): {value2}
      </Text>
      <Text fontSize="xs">
        {t('Maximum Reward Limit')}: {value3}
      </Text>

      {/* Progress Bar with Text Overlay */}
      <Box position="relative" width="100%" mt={8}>
        <Progress
          value={
            new BigNumber(auth.rewardTotalSum)
              .dividedBy(new BigNumber(auth.stakingBalance).multipliedBy(2))
              .multipliedBy(100)
              .isNaN()
              ? 0
              : new BigNumber(auth.rewardTotalSum)
                  .dividedBy(new BigNumber(auth.stakingBalance).multipliedBy(2))
                  .multipliedBy(100)
                  .toNumber()
          }
          size="sm"
          colorScheme="teal"
        />
        <Box
          position="absolute"
          top="-5"
          left="50%"
          transform="translateX(-50%)"
          width="100%"
          textAlign="center"
          zIndex={1}
        >
          <Text fontSize="xs" color="#319795" fontWeight="bold">
            {`${new BigNumber(auth.rewardTotalSum || 0).toFixed(0, 1)} USDT (${
              new BigNumber(auth.rewardTotalSum)
                .dividedBy(new BigNumber(auth.stakingBalance).multipliedBy(2))
                .multipliedBy(100)
                .isNaN()
                ? 0
                : new BigNumber(auth.rewardTotalSum)
                    .dividedBy(
                      new BigNumber(auth.stakingBalance).multipliedBy(2)
                    )
                    .multipliedBy(100)
                    .toFixed(0, 1)
            }%)`}
          </Text>
        </Box>
      </Box>
      {/* Progress Bar와 그 좌우에 값 추가 */}
      <Flex justify="space-between" mb={1}>
        <Text fontSize="xs" color="#319795">
          {0} USDT
        </Text>
        <Text fontSize="xs" color="#319795">
          {new BigNumber(auth.stakingBalance).multipliedBy(2).toFixed(0, 1)}{' '}
          USDT
        </Text>
      </Flex>

      <Grid templateColumns="repeat(2, 1fr)" gap={4} mt={4}>
        <Box textAlign="center" mt={2}>
          <Text fontSize="xs">{t('Amount for Re-participation')}</Text>
          <Text fontSize="sm">
            {new BigNumber(auth.tokenBalance).multipliedBy(rate).toFixed(0, 1)}{' '}
            USDT
          </Text>
        </Box>

        <Box>
          <Button
            bg="#33D1C9"
            color="#ffffff"
            size="sm"
            _hover={{ bg: '#2BA8A3' }}
            width="full"
            onClick={() => handleManualParticipation()}
          >
            {t('Re-participation')}
          </Button>

          <FormControl
            display="flex"
            alignItems="center"
            mt={3}
            justifyContent={'center'}
          >
            <FormLabel htmlFor="auto-participation" mb="0">
              {t('Auto Participation')}
            </FormLabel>
            <Switch
              id="auto-participation"
              colorScheme="teal"
              isChecked={auth.autoStaking === 1}
              onChange={handleToggleSwitch}
            />
          </FormControl>
        </Box>
      </Grid>

      <Box sx={{ mt: 3 }}>
        <Button
          w="100%"
          bg="#33D1C9"
          color="#ffffff"
          _hover={{ bg: '#2BA8A3' }}
          onClick={() => {
            navigate('/deposit')
          }}
        >
          {t('Staking Participation')}
        </Button>
      </Box>
    </Box>
  )
}

export default App
