import React, { useEffect, useRef, useState } from 'react'
import {
  Box,
  VStack,
  Text,
  SimpleGrid,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import {
  currentGameStatus,
  getWinnerList,
  purchaseBlock
} from '../services/apiService'

interface BlockInfo {
  blockNo: number
  userAccount?: string
}

interface GameData {
  yourBlocks: number[]
  othersBlocks: BlockInfo[]
  unpurchasedBlocks: number[]
  totalBlocks: number
  round: {
    id: number
    totalBlocks: number
    completed: boolean
    resultIdx: number | null
    winnerId: number | null
    createdAt: string
    updatedAt: string | null
    deletedAt: string | null
  }
}

const Lucky: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()

  const [gameData, setGameData] = useState<GameData | null>(null)
  const [selectedBlock, setSelectedBlock] = useState<BlockInfo | null>(null)
  const [winnerList, setWinnerList] = useState<any[]>([])
  const cancelRef = useRef<HTMLButtonElement>(null)

  // 다이얼로그 관련 상태
  const [isOpen, setIsOpen] = useState(false)

  // 안내
  const [isOpen2, setIsOpen2] = useState(false)

  const onClose = () => setIsOpen(false)
  const onClose2 = () => setIsOpen2(false)

  const [isOpenConfirm, setIsOpenConfirm] = useState(false)
  const onCloseConfirm = () => setIsOpenConfirm(false)
  const openConfirmDialog = () => setIsOpenConfirm(true)

  const [isLoading, setIsLoading] = useState(false)

  const openDialog = (block: BlockInfo) => {
    setSelectedBlock(block)
    setIsOpen(true)
  }

  const confirmPurchase = async () => {
    setIsLoading(true)
    try {
      const { data: res } = await purchaseBlock()
      if (res.statusCode === 200) {
        const data = res.data

        toast({
          title: t('Success'),
          description: `${t(res.message)} (R${data.roundId}-B${data.blockNo})`,
          status: 'success',
          duration: 2000,
          isClosable: true
        })

        onCloseConfirm() // Confirm dialog close
        initData()
      }
    } catch (error: any) {
      toast({
        title: t('Fail'),
        description: t(error.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    } finally {
      setIsLoading(false)
    }
  }

  const initData = async () => {
    try {
      const { data: res } = await currentGameStatus()
      if (res.statusCode === 200) {
        setGameData(res.data)
      }
    } catch (error: any) {
      toast({
        title: t('Fail'),
        description: t(error.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  const fetchWinnerList = async () => {
    try {
      const { data: res } = await getWinnerList()
      if (res.statusCode === 200) {
        setWinnerList(res.data) // Set the winners list
      }
    } catch (error: any) {
      toast({
        title: t('Fail'),
        description: t(error.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  useEffect(() => {
    initData()
    fetchWinnerList()
  }, [])

  const getBlockColor = (blockNo: number): string => {
    if (gameData) {
      if (gameData.yourBlocks.includes(blockNo)) return 'yellow.400' // 내가 구매한 블럭
      if (gameData.othersBlocks.some((b) => b.blockNo === blockNo))
        return 'teal.500' // 이미 팔린 블럭
      if (gameData.unpurchasedBlocks.includes(blockNo)) return 'gray.300' // 구매 가능한 블럭
    }
    return 'gray.100' // 기본 색상
  }

  return (
    <>
      <CheckAuth />

      <DefaultLayout>
        <Box px={5}>
          <Button onClick={() => setIsOpen2(true)} w={'100%'}>
            {t('Rules and Guidelines')}
          </Button>
        </Box>

        <Box px={5} mt={3}>
          <Button colorScheme="teal" onClick={openConfirmDialog} w={'100%'}>
            {t('Block Purchase')}
          </Button>
        </Box>

        <Box px={5} mt={10} textAlign="center">
          <Text fontWeight="bold">
            {t('Round')} {gameData ? gameData.round.id : '-'} ・{' '}
            {t('Block Status')}
          </Text>
        </Box>

        <VStack spacing={0} align="center" mb={10} mt={5}>
          <SimpleGrid columns={[5, 10]} spacing={3}>
            {Array.from({ length: 100 }, (_, index) => index + 1).map(
              (square) => (
                <Box
                  key={square}
                  width="50px"
                  height="50px"
                  bg={getBlockColor(square)}
                  color="white"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  fontWeight="bold"
                  borderRadius="md"
                  cursor={
                    gameData?.othersBlocks.some((b) => b.blockNo === square)
                      ? 'pointer'
                      : 'default'
                  }
                  onClick={() => {
                    const block = gameData?.othersBlocks.find(
                      (b) => b.blockNo === square
                    )
                    if (block) openDialog(block) // 이미 팔린 블럭인 경우 다이얼로그 열기
                  }}
                >
                  {square}
                </Box>
              )
            )}
          </SimpleGrid>
        </VStack>

        {/* Display the Winner List */}
        {winnerList.length !== 0 && (
          <>
            <Box px={5} mt={10} pb={10}>
              <Text fontWeight="bold">{t('Hall of Fame')}</Text>
              <VStack spacing={2} align="start" mt={3}>
                {winnerList.map((winner, index) => (
                  <Box
                    key={index}
                    p={3}
                    borderWidth="1px"
                    borderRadius="md"
                    w="100%"
                    bg="white"
                  >
                    <Text>{`${t('Round')}: ${winner.roundId}`}</Text>
                    <Text>{`${t('Lucky Winner')}: ${winner.winnerName}`}</Text>
                    <Text>{`${t('Lucky Block Number')}: ${
                      winner.resultIdx
                    }`}</Text>
                  </Box>
                ))}
              </VStack>
            </Box>
          </>
        )}

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent mx={3}>
            <ModalHeader>{t('Block Details')}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              {selectedBlock ? (
                <>
                  <Text fontWeight="bold">
                    {t('Block Number')}: {selectedBlock.blockNo}
                  </Text>
                  <Text>
                    {t('Owner')}: {selectedBlock.userAccount || 'Unknown'}
                  </Text>
                </>
              ) : (
                <Text>{t('No information available')}</Text>
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose}>{t('Close')}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={isOpen2} onClose={onClose2}>
          <ModalOverlay />
          <ModalContent mx={3}>
            <ModalHeader>{t('Rules and Guidelines')}</ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Text fontWeight="bold">
                {t('Participation Conditions and Reward Information')}
              </Text>
              <br />-{' '}
              {t(
                'This event involves selecting a specific block through purchase to participate.'
              )}
              <br />-{' '}
              {t(
                'Buyers secure the right to purchase the selected block, which is regarded as a simple digital product purchase.'
              )}
              <br />-{' '}
              {t(
                'One block will be randomly drawn from the selected blocks. The owner of the drawn block will be eligible to receive a special reward.'
              )}
              <br />
              <br />
              <Text fontWeight="bold">
                {t('Reward Mechanism and Distribution')}
              </Text>
              <br />-{' '}
              {t(
                'The reward will be provided as a digital asset amounting to 100% of the total block purchases. This reward serves as a gesture of appreciation for participation and support.'
              )}
              <br />-{' '}
              {t(
                'The reward is merely a benefit of participation and does not constitute a lottery or prize drawing.'
              )}
              <br />
              <br />
              <Text fontWeight="bold">{t('Disclaimer')}</Text>
              <br />-{' '}
              {t(
                'This event includes gamified purchasing activities and does not contain any elements of gambling.'
              )}
              <br />-{' '}
              {t(
                'This event is not subject to any national laws related to lotteries, raffles, or gambling regulations.'
              )}
              <br />-{' '}
              {t(
                'Buyers cannot request refunds for purchased blocks, and the organizer retains final discretion over the reward distribution.'
              )}
              <br />
              <br />
              <Text fontWeight="bold">
                {t('Compliance with Legal Regulations')}
              </Text>
              <br />-{' '}
              {t(
                'This event will be conducted transparently and in full compliance with local regulations.'
              )}
              <br />-{' '}
              {t(
                'The organizer reserves the right to modify or adjust the event as necessary to ensure compliance with relevant legal requirements.'
              )}
            </ModalBody>

            <ModalFooter>
              <Button onClick={onClose2}>{t('Close')}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <AlertDialog
          isOpen={isOpenConfirm}
          leastDestructiveRef={cancelRef}
          onClose={onCloseConfirm}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t('Confirm Purchase')}
              </AlertDialogHeader>

              <AlertDialogBody>
                <Text>
                  {t('Are you sure you want to purchase this block?')}
                </Text>
                <Text>{t('The price of a block is 1 WVP.')}</Text>
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={onCloseConfirm}>
                  {t('Cancel')}
                </Button>
                <Button
                  colorScheme="teal"
                  onClick={confirmPurchase}
                  ml={3}
                  isLoading={isLoading}
                >
                  {t('Purchase')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </DefaultLayout>
    </>
  )
}

export default Lucky
