import BigNumber from 'bignumber.js'
import React, { useState, useEffect } from 'react'
import Web3 from 'web3'
import { Button } from '@chakra-ui/react'
import { Input } from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'

declare global {
  interface Window {
    ethereum: any
  }
}

const Sample: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [web3, setWeb3] = useState<Web3 | null>(null)
  const [account, setAccount] = useState<string | null>(null)
  const [tokenBalance, setTokenBalance] = useState<string | null>(null)
  const [recipientAddress, setRecipientAddress] = useState<string>('')
  const [amount, setAmount] = useState<string>('')

  const tokenAddress = '0xEfd5A7D584F892dD0E757ee2Fdc89FeF9F6C0757'
  const ERC20_ABI = [
    {
      constant: true,
      inputs: [{ name: '_owner', type: 'address' }],
      name: 'balanceOf',
      outputs: [{ name: 'balance', type: 'uint256' }],
      type: 'function'
    },
    {
      constant: false,
      inputs: [
        { name: '_to', type: 'address' },
        { name: '_value', type: 'uint256' }
      ],
      name: 'transfer',
      outputs: [{ name: '', type: 'bool' }],
      type: 'function'
    }
  ]

  useEffect(() => {
    if (window.ethereum) {
      const web3Instance = new Web3(window.ethereum)
      setWeb3(web3Instance)

      const savedAccount = localStorage.getItem('account')
      if (savedAccount) {
        if (web3Instance.utils.isAddress(savedAccount)) {
          setAccount(savedAccount)
        } else {
          disconnectWallet() // Invalid address, disconnect
        }
      }

      // Handle account change
      window.ethereum.on('accountsChanged', function (accounts: string[]) {
        if (accounts.length > 0) {
          setAccount(accounts[0])
          localStorage.setItem('account', accounts[0])
        } else {
          disconnectWallet()
        }
      })
    } else {
      console.error('Web3 wallet is not installed.')
    }
  }, [])

  // Fetch the token balance when the account changes
  useEffect(() => {
    if (account) {
      getTokenBalance(account)
    }
  }, [account])

  const connectWallet = async () => {
    if (web3) {
      try {
        const accounts = await web3.eth.requestAccounts()
        if (web3.utils.isAddress(accounts[0])) {
          setAccount(accounts[0])
          localStorage.setItem('account', accounts[0])
        } else {
          console.error('Invalid address returned from wallet.')
        }
      } catch (error) {
        console.error('Failed to connect wallet:', error)
      }
    } else {
      console.error('No Web3 instance available.')
    }
  }

  const disconnectWallet = () => {
    setAccount(null)
    setTokenBalance(null)
    localStorage.removeItem('account')
  }

  const getTokenBalance = async (account: string) => {
    if (web3) {
      try {
        const tokenContract: any = new web3.eth.Contract(
          ERC20_ABI,
          tokenAddress
        )
        const balance: string = await tokenContract.methods
          .balanceOf(account)
          .call()

        const balanceBN = new BigNumber(balance)
        const decimals = 8 // Assuming the token has 8 decimals
        const formattedBalance = balanceBN
          .dividedBy(new BigNumber(10).pow(decimals))
          .toFixed(decimals)

        setTokenBalance(formattedBalance)
      } catch (error) {
        console.error('Failed to get token balance:', error)
      }
    } else {
      console.error('No Web3 instance available.')
    }
  }

  const handleTransfer = async () => {
    if (web3 && account && recipientAddress && amount) {
      try {
        const tokenContract: any = new web3.eth.Contract(
          ERC20_ABI,
          tokenAddress
        )
        const decimals = 8 // Assuming the token has 8 decimals
        const amountBN = new BigNumber(amount) // Convert the amount to BigNumber

        console.log('amountBN => ', amountBN)

        const amountToSend = amountBN.multipliedBy(
          new BigNumber(10).pow(decimals)
        )

        console.log('amountToSend => ', amountToSend)

        const transaction = await tokenContract.methods
          .transfer(recipientAddress, amountToSend.toFixed())
          .send({ from: account })

        console.log('Transaction successful:', transaction)
        getTokenBalance(account) // Refresh token balance after transfer
      } catch (error) {
        console.error('Failed to send tokens:', error)
      }
    } else {
      console.error('Invalid input for transfer.')
    }
  }

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  return (
    <div>
      <div>
        <h1>{t('welcome')}</h1>
        <p>{t('description')}</p>
        <Button onClick={() => changeLanguage('en')}>English</Button>
        <Button onClick={() => changeLanguage('ko')}>한국어</Button>
      </div>

      <h1>Web3 Wallet Connection Example</h1>
      {account ? (
        <div>
          <p>Wallet Address: {account}</p>
          <p>
            WAP Token Balance:{' '}
            {tokenBalance !== null ? `${tokenBalance} WAP` : 'Loading...'}
          </p>

          <div>
            <h3>Send Tokens</h3>
            <Input
              placeholder="Recipient Address"
              value={recipientAddress}
              onChange={(e) => setRecipientAddress(e.target.value)}
            />
            <Input
              placeholder="Amount"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
            <Button colorScheme="blue" onClick={handleTransfer}>
              Send Tokens
            </Button>
          </div>

          <Button colorScheme="blue" onClick={disconnectWallet}>
            Disconnect Wallet
          </Button>
        </div>
      ) : (
        <Button colorScheme="blue" onClick={connectWallet}>
          Connect Wallet
        </Button>
      )}
    </div>
  )
}

export default Sample
