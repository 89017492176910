import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  Text,
  Image,
  Spinner,
  VStack,
  useToast,
  Button,
  HStack,
  Icon,
  Flex
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import { useNavigate, useParams } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { getHotel } from '../services/apiService'
import { FaCheckCircle } from 'react-icons/fa' // 체크 아이콘 사용
import { ArrowBackIcon } from '@chakra-ui/icons'

interface Hotel {
  id: number
  name: string
  desc: string
  price: string
  star: number
  address: string
  mainImage: string | null
  subImage: string[]
  latitude: string
  longitude: string
  features: string
  foodAndBeverage: string
  wellness: string
  activities: string
  entertainment: string
  amenities: string
}

const HotelDetail: React.FC = () => {
  const { id } = useParams()
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()
  const [hotel, setHotel] = useState<Hotel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const navigate = useNavigate()

  const clickReservationRequest = () => {
    toast({
      title: t('Reservation Request'),
      description: t('This feature is under development.'),
      status: 'warning',
      duration: 2000,
      isClosable: true
    })
  }

  useEffect(() => {
    const fetchHotel = async () => {
      try {
        const { data: res } = await getHotel({ hotelId: Number(id) })
        if (res.statusCode === 200) {
          setHotel(res.data)
        }
      } catch (error) {
        // error
      } finally {
        setLoading(false)
      }
    }
    fetchHotel()
  }, [id, toast])

  if (loading) {
    return (
      <DefaultLayout>
        <Box textAlign="center" mt={20}>
          <Spinner size="xl" />
        </Box>
      </DefaultLayout>
    )
  }

  if (!hotel) {
    return (
      <DefaultLayout>
        <Box textAlign="center" mt={20}>
          <Text>{t('Hotel not found.')}</Text>
        </Box>
      </DefaultLayout>
    )
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Flex justifyContent="right" alignItems="center" mt={-4}>
          <Button
            onClick={() => {
              navigate('/hotel')
            }}
            leftIcon={<ArrowBackIcon />}
            variant="ghost"
            size="sm"
          >
            {t('Go Back')}
          </Button>
        </Flex>

        <Box bg={'white'} borderWidth="1px" borderRadius="lg">
          {hotel.mainImage && (
            <Image
              src={hotel.mainImage}
              alt={hotel.name}
              width="100%"
              height="200px"
              objectFit="cover"
              borderRadius="lg"
            />
          )}
          <Box px={3} py={5}>
            <Heading size="lg">{hotel.name}</Heading>
            <Text>
              {Array(hotel.star)
                .fill('')
                .map((_, index) => (
                  <span key={index}>⭐</span>
                ))}
            </Text>

            <Text fontSize="small" mt={3}>
              {t('Address')}: {hotel.address}
            </Text>

            <Text fontSize="xs" mt={3}>
              {hotel.desc}
            </Text>

            {hotel.features && (
              <Box mt={3}>
                <Text fontWeight="bold">{t('Key Features')}</Text>
                <VStack align="start" spacing={2} mt={2}>
                  {hotel.features.split('#').map((item, index) => (
                    <HStack key={index} align="center">
                      <Icon as={FaCheckCircle} color="green.500" />{' '}
                      {/* 체크 아이콘 */}
                      <Text fontSize="sm">{item.trim()}</Text>{' '}
                      {/* 트림으로 공백 제거 */}
                    </HStack>
                  ))}
                </VStack>
              </Box>
            )}

            {hotel.foodAndBeverage && (
              <Box mt={3}>
                <Text fontWeight="bold">{t('Food & Beverage')}</Text>
                <VStack align="start" spacing={2} mt={2}>
                  {hotel.foodAndBeverage.split('#').map((item, index) => (
                    <HStack key={index} align="center">
                      <Icon as={FaCheckCircle} color="blue.500" />{' '}
                      {/* 체크 아이콘 */}
                      <Text fontSize="sm">{item.trim()}</Text>{' '}
                      {/* 트림으로 공백 제거 */}
                    </HStack>
                  ))}
                </VStack>
              </Box>
            )}

            {hotel.wellness && (
              <Box mt={3}>
                <Text fontWeight="bold">{t('Wellness')}</Text>
                <VStack align="start" spacing={2} mt={2}>
                  {hotel.wellness.split('#').map((item, index) => (
                    <HStack key={index} align="center">
                      <Icon as={FaCheckCircle} color="blue.500" />{' '}
                      {/* 체크 아이콘 */}
                      <Text fontSize="sm">{item.trim()}</Text>{' '}
                      {/* 트림으로 공백 제거 */}
                    </HStack>
                  ))}
                </VStack>
              </Box>
            )}

            {hotel.activities && (
              <Box mt={3}>
                <Text fontWeight="bold">{t('Activities')}</Text>
                <VStack align="start" spacing={2} mt={2}>
                  {hotel.activities.split('#').map((item, index) => (
                    <HStack key={index} align="center">
                      <Icon as={FaCheckCircle} color="blue.500" />{' '}
                      {/* 체크 아이콘 */}
                      <Text fontSize="sm">{item.trim()}</Text>{' '}
                      {/* 트림으로 공백 제거 */}
                    </HStack>
                  ))}
                </VStack>
              </Box>
            )}

            {hotel.entertainment && (
              <Box mt={3}>
                <Text fontWeight="bold">{t('Entertainment')}</Text>
                <VStack align="start" spacing={2} mt={2}>
                  {hotel.entertainment.split('#').map((item, index) => (
                    <HStack key={index} align="center">
                      <Icon as={FaCheckCircle} color="blue.500" />{' '}
                      {/* 체크 아이콘 */}
                      <Text fontSize="sm">{item.trim()}</Text>{' '}
                      {/* 트림으로 공백 제거 */}
                    </HStack>
                  ))}
                </VStack>
              </Box>
            )}

            {hotel.amenities && (
              <Box mt={3}>
                <Text fontWeight="bold">{t('Amenities')}</Text>
                <VStack align="start" spacing={2} mt={2}>
                  {hotel.amenities.split('#').map((item, index) => (
                    <HStack key={index} align="center">
                      <Icon as={FaCheckCircle} color="green.500" />{' '}
                      {/* 체크 아이콘 */}
                      <Text fontSize="sm">{item.trim()}</Text>{' '}
                      {/* 트림으로 공백 제거 */}
                    </HStack>
                  ))}
                </VStack>
              </Box>
            )}

            <Box mt={5}>
              <Text fontWeight="bold" color="#33D1C9" textAlign={'center'}>
                {t('Price for 1 Night, 2 Days')}
              </Text>
              <Text
                fontWeight="bold"
                color="#33D1C9"
                mt={-2}
                textAlign={'center'}
                fontSize={'xx-large'}
              >
                {new BigNumber(hotel.price).toFixed(2, 1)} WVP
              </Text>
            </Box>
          </Box>
        </Box>

        <Box pb={10}>
          <Button
            w={'100%'}
            mt={4}
            size="lg"
            bg="#33D1C9"
            color="#ffffff"
            _hover={{ bg: '#2BA8A3' }}
            onClick={clickReservationRequest}
          >
            {t('Reservation Request')}
          </Button>
        </Box>
      </DefaultLayout>
    </>
  )
}

export default HotelDetail
