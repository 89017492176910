import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Text
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { inviteState } from '../atoms/invite'
import { useNavigate } from 'react-router-dom'
import { signUp } from '../services/apiService'
import { AxiosError } from 'axios'

const SignUp: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const [invite] = useRecoilState(inviteState)
  const toast = useToast()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false) // Add loading state

  // Validation schema using Yup
  const validationSchema = Yup.object({
    username: Yup.string()
      .min(3, t('Username must be at least 3 characters'))
      .required(t('Username is required')),
    password: Yup.string()
      .min(8, t('Password must be at least 8 characters'))
      .required(t('Password is required')),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('Passwords must match'))
      .required(t('Confirm Password is required')),
    referralCode: Yup.string()
      .length(8, t('Referral Code must be exactly 8 characters'))
      .required(t('Referral Code is required')),
    sponsorCode: Yup.string()
      .length(8, t('Sponsor Code must be exactly 8 characters'))
      .required(t('Sponsor Code is required'))
  })

  const initialValues = {
    username: '',
    password: '',
    confirmPassword: '',
    referralCode: invite || '', // Pre-fill with invite code if available
    sponsorCode: invite || '' // Pre-fill with invite code if available
  }

  const handleSubmit = async (values: typeof initialValues) => {
    setLoading(true) // Start loading

    try {
      const { data: res } = await signUp({
        walletAddress: auth.walletAddress,
        username: values.username,
        password: values.password,
        referralCode: values.referralCode,
        sponsorCode: values.sponsorCode
      })
      if (res.statusCode === 200) {
        toast({
          title: t('Success'),
          description: t("You've successfully signed up."),
          status: 'success',
          duration: 1500,
          isClosable: true
        })

        navigate('/signIn')
      }
    } catch (err: any) {
      toast({
        title: t('Fail'),
        description: t(err.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    } finally {
      setLoading(false) // Stop loading
    }
  }

  // ** Lifecycle
  useEffect(() => {
    if (!auth.walletAddress) {
      navigate('/')
    }
  }, [])

  return (
    <DefaultLayout showHamburgerMenu={false}>
      <Box
        maxW="md"
        mx="auto"
        mt={2}
        mb={4}
        p={6}
        borderWidth={1}
        borderRadius="lg"
        bg={'white'}
      >
        <Heading as="h2" mb={6} textAlign="center">
          {t('Sign Up')}
        </Heading>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {() => (
            <Form>
              <VStack spacing={4}>
                <FormControl id="walletAddress" isRequired>
                  <FormLabel>{t('Wallet Address')}</FormLabel>
                  <Input
                    type="text"
                    value={auth.walletAddress ? auth.walletAddress : ''}
                    readOnly
                    sx={{
                      bg: 'gray.100'
                    }}
                  />
                </FormControl>
                <FormControl id="username">
                  <FormLabel>{t('Username')}</FormLabel>
                  <Field name="username">
                    {({ field }: { field: any }) => (
                      <Input type="text" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="username">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>
                <FormControl id="password">
                  <FormLabel>{t('Password')}</FormLabel>
                  <Field name="password">
                    {({ field }: { field: any }) => (
                      <Input type="password" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="password">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>
                <FormControl id="confirmPassword">
                  <FormLabel>{t('Confirm Password')}</FormLabel>
                  <Field name="confirmPassword">
                    {({ field }: { field: any }) => (
                      <Input type="password" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="confirmPassword">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>
                <FormControl id="referralCode">
                  <FormLabel>{t('Referral Code')}</FormLabel>
                  <Field name="referralCode">
                    {({ field }: { field: any }) => (
                      <Input
                        type="text"
                        {...field}
                        readOnly={!!invite} // Make read-only if invite code is present
                        sx={invite ? { bg: 'gray.100' } : {}}
                        bg={'white'}
                      />
                    )}
                  </Field>
                  <ErrorMessage name="referralCode">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>
                <FormControl id="sponsorCode">
                  <FormLabel>{t('Sponsor Code')}</FormLabel>
                  <Field name="sponsorCode">
                    {({ field }: { field: any }) => (
                      <Input type="text" {...field} bg={'white'} />
                    )}
                  </Field>
                  <ErrorMessage name="sponsorCode">
                    {(msg) => <Text color="red.500">{msg}</Text>}
                  </ErrorMessage>
                </FormControl>
                <Button
                  type="submit"
                  bg="#33D1C9"
                  color="#ffffff"
                  size="lg"
                  _hover={{ bg: '#2BA8A3' }}
                  width="full"
                  isLoading={loading} // Add loading state to the button
                >
                  {t('Sign Up')}
                </Button>
              </VStack>
            </Form>
          )}
        </Formik>
      </Box>
    </DefaultLayout>
  )
}

export default SignUp
