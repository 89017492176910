// LottieComponent.jsx
import React from 'react'
import Lottie from 'react-lottie'

const EasyLottie = ({
  animationData,
  height = 200,
  width = 200,
  loop = true,
  autoplay = true
}) => {
  const defaultOptions = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  return <Lottie options={defaultOptions} height={height} width={width} />
}

export default EasyLottie
