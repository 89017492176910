import { useEffect, useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  useToast
} from '@chakra-ui/react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { useNavigate } from 'react-router-dom'
import { signIn } from '../services/apiService'

const SignIn = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()

  const [walletAddress, setWalletAddress] = useState('')
  const [password, setPassword] = useState('')
  const [auth] = useRecoilState(authState)

  const handleSignIn = async () => {
    try {
      const { data: res } = await signIn({
        walletAddress: auth.walletAddress,
        password: password
      })
      if (res.statusCode === 200) {
        navigate('/app')

        // toast({
        //   title: t('Success'),
        //   description: t('Welcome to Wave Project'),
        //   status: 'success',
        //   duration: 1500,
        //   isClosable: true
        // })
      }
    } catch (err: any) {
      toast({
        title: t('Fail'),
        description: t(err.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  // ** Lifecycle
  useEffect(() => {
    if (!auth.walletAddress) {
      navigate('/')
    }
  }, [])

  return (
    <DefaultLayout showHamburgerMenu={false}>
      <Box maxW="sm" mx="auto" mt="8">
        <Heading as="h1" mb="8" textAlign="center">
          {t('Sign In')}
        </Heading>
        <Stack spacing="4">
          <FormControl id="wallet-address">
            <FormLabel>{t('Wallet Address')}</FormLabel>
            <Input
              type="text"
              value={auth.walletAddress ? auth.walletAddress : ''}
              readOnly
              sx={{
                bg: 'gray.100'
              }}
            />
          </FormControl>

          <FormControl id="password">
            <FormLabel>{t('Password')}</FormLabel>
            <Input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t('Please enter your password')}
              bg={'white'}
            />
          </FormControl>

          <Button
            type="submit"
            bg="#33D1C9"
            color="#ffffff"
            size="lg"
            _hover={{ bg: '#2BA8A3' }}
            width="full"
            onClick={handleSignIn}
          >
            {t('Sign In')}
          </Button>
        </Stack>
      </Box>
    </DefaultLayout>
  )
}

export default SignIn
