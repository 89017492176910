import { useRecoilState } from 'recoil'
import { getUser, getWVPUSDT } from '../../services/apiService'
import { authState } from '../../atoms/auth'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { rateState } from '../../atoms/rate'

const CheckAuth = () => {
  const [auth, setAuth] = useRecoilState(authState)
  const [rate, setRate] = useRecoilState(rateState)
  const navigate = useNavigate()

  // 방어 로직
  const initData = async () => {
    try {
      const { data: res } = await getUser()
      if (res.statusCode === 200) {
        const user = res.data

        // DEBUG
        console.log('user => ', user)

        // NOTE setAuth수정
        setAuth({
          walletAddress: user.walletAddress,
          username: user.username,
          tokenBalance: user.tokenBalance,
          stakingBalance: user.stakingBalance,
          stakingPercent: user.stakingPercent,
          limitReward: user.limitReward,
          autoStaking: user.autoStaking,
          code: user.code,
          userLevel: user.userLevel,
          wvpLock: user.wvpLock,

          rewardTeamSum: user.rewardTeamSum,
          rewardVolumeSum: user.rewardVolumeSum,
          rewardWaveSum: user.rewardWaveSum,
          rewardTotalSum: user.rewardTotalSum,

          valueSup: user.valueSup,
          valueRef: user.valueRef,
          listSupLength: user.listSupLength,
          listRefLength: user.listRefLength
        })
        // 추가적인 사용자 데이터가 있다면 여기에 반영하세요
      }
    } catch (err: any) {
      navigate('/')
    }
  }

  const fetchPrice = async () => {
    try {
      const { data: res } = await getWVPUSDT()
      if (res.statusCode === 200) {
        const latestPrice = res.data
        console.log('latestPrice => ', latestPrice)
        setRate(latestPrice)
      }
    } catch (err: any) {
      setRate(0)
    }
  }

  // ** Lifecycle
  useEffect(() => {
    initData()

    fetchPrice()

    // 10초마다 latestPrice 업데이트
    const interval = setInterval(() => {
      fetchPrice()
    }, 10000) // 10초 간격으로 호출

    // 컴포넌트가 언마운트될 때 interval 정리
    return () => {
      console.log('unmount interval')
      clearInterval(interval)
    }
  }, [])

  return <></>
}

export default CheckAuth
