// src/atoms/auth.ts
import { atom } from 'recoil'

interface AuthState {
  walletAddress: string | null
  username: string
  tokenBalance: string
  stakingBalance: string
  stakingPercent: number
  limitReward: string
  autoStaking: number
  code: string
  userLevel: number
  wvpLock: string | null

  rewardTeamSum: string
  rewardVolumeSum: string
  rewardWaveSum: string
  rewardTotalSum: string

  valueSup: string
  valueRef: string
  listSupLength: number
  listRefLength: number
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    walletAddress: null,
    username: '',
    tokenBalance: '0.00000000',
    stakingBalance: '0.00000000',
    stakingPercent: 0,
    limitReward: '0.00000000',
    autoStaking: 0,
    code: '',
    userLevel: 0,
    wvpLock: null,

    rewardTeamSum: '0.00000000',
    rewardVolumeSum: '0.00000000',
    rewardWaveSum: '0.00000000',
    rewardTotalSum: '0.00000000',

    valueSup: '0.00000000',
    valueRef: '0.00000000',
    listSupLength: 0,
    listRefLength: 0
  }
})
