import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { Text, Button, Flex, Box, useToast } from '@chakra-ui/react'
import Lottie from 'react-lottie'
import animationData from '../lotties/network.json'
import Web3 from 'web3'
import { useEffect, useState } from 'react'
import { authState } from '../atoms/auth'
import { useRecoilState } from 'recoil'
import { abbreviateAddress } from '../utils'
import { existWallet } from '../services/apiService'
import { useNavigate, useLocation } from 'react-router-dom'
import { inviteState } from '../atoms/invite'

const Home = () => {
  // ** Hooks
  const { t } = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()
  const location = useLocation()

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

  // ** State
  const [auth, setAuth] = useRecoilState(authState)
  const [invite, setInvite] = useRecoilState(inviteState)

  // ** Lifecycle
  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const inviteCode = params.get('inviteCode')

    if (inviteCode) {
      setInvite(inviteCode)
    }
  }, [location.search, setInvite])

  // ** Handler
  const connectWallet = async () => {
    const web3 = new Web3(window.ethereum)

    if (web3) {
      try {
        const accounts = await web3.eth.requestAccounts()

        if (web3.utils.isAddress(accounts[0])) {
          // 리코일에 지갑 저장
          setAuth({ ...auth, walletAddress: accounts[0] })

          try {
            const { data: res } = await existWallet({
              walletAddress: accounts[0]
            })
            if (res.statusCode === 200) {
              const data = res.data

              if (data) {
                navigate('/signIn')
              } else {
                navigate('/signUp')
              }
            }
          } catch (err) {
            //
          }

          // toast({
          //   title: t('Wallet Connected'),
          //   description:
          //     t('Connected to wallet: ') + abbreviateAddress(accounts[0]),
          //   status: 'success',
          //   duration: 1500,
          //   isClosable: true
          // })
        } else {
          toast({
            title: t('Invalid Address'),
            description: t('Invalid address returned from wallet.'),
            status: 'error',
            duration: 1500,
            isClosable: true
          })
        }
      } catch (error: any) {
        toast({
          title: t('Connection Failed'),
          description: t('Failed to connect wallet: ') + error.message,
          status: 'error',
          duration: 1500,
          isClosable: true
        })
      }
    } else {
      toast({
        title: t('No Web3 Instance'),
        description: t('No Web3 instance available.'),
        status: 'warning',
        duration: 1500,
        isClosable: true
      })
    }
  }

  return (
    <DefaultLayout showHamburgerMenu={false}>
      <Flex direction="column" align="center" minH="100vh">
        <Box textAlign="center" mt={4}>
          <Lottie options={defaultOptions} height={200} width={200} />
        </Box>
        <Box textAlign="center" mt={8}>
          <Text fontSize="lg">{t('Welcome to Wave Project dApp!')}</Text>
          <Text fontSize="lg">{t('Please connect the wallet.')}</Text>
        </Box>
        <Box textAlign="center" mt={4}>
          <Button
            bg="#33D1C9"
            color="#ffffff"
            size="lg"
            _hover={{ bg: '#2BA8A3' }}
            onClick={() => connectWallet()}
          >
            {t('Connect Wallet')}
          </Button>
        </Box>
      </Flex>
    </DefaultLayout>
  )
}

export default Home
