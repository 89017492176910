import { Container } from '@chakra-ui/react'
import AppBar from './AppBar'
import Wave from 'react-wavify'
import { useEffect } from 'react'
import Web3 from 'web3'
import { useRecoilState } from 'recoil'
import { useNavigate } from 'react-router-dom'
import { authState } from '../../atoms/auth'

interface DefaultLayoutProps {
  children: React.ReactNode
  showHamburgerMenu?: boolean
}

const DefaultLayout = ({
  children,
  showHamburgerMenu = true
}: DefaultLayoutProps) => {
  const navigate = useNavigate()
  const [auth, setAuth] = useRecoilState(authState)

  // ** Lifecycle
  useEffect(() => {
    if (window.ethereum) {
      // Handle account change
      window.ethereum.on('accountsChanged', function (accounts: string[]) {
        setAuth({ ...auth, walletAddress: null })
        navigate('/')
      })
    } else {
      console.error('Web3 wallet is not installed.')
    }
  }, [])

  return (
    <>
      <AppBar showHamburgerMenu={showHamburgerMenu} />
      <Container maxW="1200px" mt={4}>
        {children}
      </Container>
      <Wave
        fill="#f5b13a"
        paused={false}
        style={{ position: 'fixed', bottom: 0, width: '100%', zIndex: -1 }}
        options={{
          height: 20,
          amplitude: 30,
          speed: 0.2,
          points: 4
        }}
      />
    </>
  )
}

export default DefaultLayout
