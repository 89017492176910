import { useState, useRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import {
  Button,
  Grid,
  Box,
  Text,
  Heading,
  useToast,
  useClipboard,
  Input,
  Spinner,
  Checkbox
} from '@chakra-ui/react'
import Web3 from 'web3'
import BigNumber from 'bignumber.js'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay
} from '@chakra-ui/react'
import { deposit, getWVPUSDT } from '../services/apiService'
import { useUpdateAuthState } from '../utils/authUtils'
import { rateState } from '../atoms/rate'

const Deposit = () => {
  const { t } = useTranslation()
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [isManualDialogOpen, setIsManualDialogOpen] = useState(false)
  const [txId, setTxId] = useState('')
  const [manualTxId, setManualTxId] = useState('')
  const updateAuthState = useUpdateAuthState()
  const { onCopy } = useClipboard(txId)
  const cancelRef = useRef<HTMLButtonElement>(null)

  const [isLoading, setIsLoading] = useState(false) // 로딩 상태 추가
  const [isChecked, setIsChecked] = useState(false) // 체크박스 상태 추가
  const [isManualProcessingLoading, setIsManualProcessingLoading] =
    useState(false) // New state for manual processing loading

  const onClose = () => setIsDialogOpen(false)
  const onManualClose = () => setIsManualDialogOpen(false)

  // WVP/USDT 가격
  const [rate, setRate] = useRecoilState(rateState)

  // web3 지갑 WVP 잔액
  const [balance, setBalance] = useState('0')

  const plans = [
    { amount: 100, dailyReward: '0.4%', maxReward: '1,000,000.00 WVP' },
    { amount: 300, dailyReward: '0.4%', maxReward: '1,000,000.00 WVP' },
    { amount: 500, dailyReward: '0.4%', maxReward: '1,000,000.00 WVP' },
    { amount: 1500, dailyReward: '0.45%', maxReward: '6,000,000.00 WVP' },
    { amount: 3000, dailyReward: '0.45%', maxReward: '6,000,000.00 WVP' },
    { amount: 7000, dailyReward: '0.45%', maxReward: '6,000,000.00 WVP' },
    { amount: 10000, dailyReward: '0.5%', maxReward: '15,000,000.00 WVP' },
    { amount: 30000, dailyReward: '0.5%', maxReward: '15,000,000.00 WVP' },
    { amount: 50000, dailyReward: '0.5%', maxReward: '15,000,000.00 WVP' }
  ]

  const [selectedPlan, setSelectedPlan] = useState(plans[0])

  const handlePlanSelection = (plan: any) => {
    setSelectedPlan(plan)
  }

  const handleTokenTransfer = async () => {
    setIsLoading(true) // 로딩 상태로 설정
    const tokenAddress = '0x55d398326f99059ff775485246999027b3197955' // USDT
    const ERC20_ABI = [
      {
        constant: true,
        inputs: [{ name: '_owner', type: 'address' }],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256' }],
        type: 'function'
      },
      {
        constant: false,
        inputs: [
          { name: '_to', type: 'address' },
          { name: '_value', type: 'uint256' }
        ],
        name: 'transfer',
        outputs: [{ name: '', type: 'bool' }],
        type: 'function'
      }
    ]
    // web3
    const web3 = new Web3(window.ethereum)

    // 토큰 계약
    const tokenContract: any = new web3.eth.Contract(ERC20_ABI, tokenAddress)

    // 소수점
    const decimals = 18

    // 선택한 플랜의 USDT 금액
    const amount = selectedPlan.amount

    // 선택한 플랜의 USDT 금액을 WVP로 환산
    const amountBN = new BigNumber(
      new BigNumber(amount).dividedBy(rate).toFixed(2, 1)
    )

    // 전송 단위로 환산
    const amountToSend = amountBN.multipliedBy(new BigNumber(10).pow(decimals))

    // 입금지갑
    const depositWalletAddress = process.env.REACT_APP_SYSTEM_WALLET_ADDRESS

    // 사용자지갑
    const userWalletAddress = auth.walletAddress

    try {
      // 토큰 전송
      const transaction = await tokenContract.methods
        .transfer(depositWalletAddress, amountToSend.toFixed())
        .send({ from: userWalletAddress })

      setTxId(transaction.transactionHash)
      setIsDialogOpen(true)
      setIsLoading(false) // 로딩 상태 해제
    } catch (err: any) {
      console.log('err => ', err)

      if (err.message.includes('User denied transaction signature')) {
        toast({
          title: t('Fail'),
          description: t('Transaction was rejected by the user.'),
          status: 'warning',
          duration: 1500,
          isClosable: true
        })
      } else if (err.message.includes('insufficient funds')) {
        toast({
          title: t('Fail'),
          description: t('Transaction failed due to insufficient funds.'),
          status: 'error',
          duration: 1500,
          isClosable: true
        })
      } else if (err.message.includes('network error')) {
        toast({
          title: t('Fail'),
          description: t('Transaction failed due to a network error.'),
          status: 'error',
          duration: 1500,
          isClosable: true
        })
      } else {
        toast({
          title: t('Fail'),
          description: t('An unexpected error occurred: ') + err.message,
          status: 'error',
          duration: 1500,
          isClosable: true
        })
      }
      setIsLoading(false) // 로딩 상태 해제
    } finally {
      setIsLoading(false) // 로딩 상태 해제
    }
  }

  const handleManualProcessing = async () => {
    setIsManualProcessingLoading(true)
    try {
      const { data: res } = await deposit({
        txId: manualTxId
      })
      if (res.statusCode === 200) {
        await updateAuthState()
        toast({
          title: t('Success'),
          description: t('Manual transaction was successful.'),
          status: 'success',
          duration: 1500,
          isClosable: true
        })
        onManualClose()

        // 폼 초기화
        setManualTxId('')
      }
    } catch (error: any) {
      toast({
        title: t('Fail'),
        description: t(error.response.data.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    } finally {
      setIsManualProcessingLoading(false) // Reset loading state after processing
    }
  }

  useEffect(() => {
    const getTokenBalance = async () => {
      // Web3 인스턴스 생성 (사용자의 지갑 연결 필요)
      const web3 = new Web3(window.ethereum)

      // 토큰의 ERC20 ABI
      const ERC20_ABI = [
        {
          constant: true,
          inputs: [{ name: '_owner', type: 'address' }],
          name: 'balanceOf',
          outputs: [{ name: 'balance', type: 'uint256' }],
          type: 'function'
        }
      ]

      // 토큰 계약
      const tokenAddress = '0x55d398326f99059fF775485246999027B3197955' // USDT
      const tokenContract = new web3.eth.Contract(ERC20_ABI, tokenAddress)

      try {
        // 토큰 보유 수량 가져오기
        const result: any = await tokenContract.methods
          .balanceOf(auth.walletAddress)
          .call()

        // 소수점 보정을 위해서 decimals 값이 필요할 수도 있음 (8을 기준으로 예시)
        const decimals = 18

        // 가져온 값은 보통 18자리 또는 지정된 소수점을 포함한 큰 수이므로 BigNumber로 처리
        const balanceBN = new BigNumber(result).dividedBy(
          new BigNumber(10).pow(decimals)
        )

        // React 상태에 저장
        setBalance(balanceBN.toFixed(2)) // 소수점 두 자리로 표시
      } catch (err) {
        console.error('Error fetching token balance:', err)
        setBalance('-')
      }
    }

    // 함수 호출
    // getTokenBalance()
  }, [])

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={1}>
          <Heading as="h4" size="md">
            {t('Staking')}
          </Heading>
          <Text mt={0.5}>
            {t('Please select the plan you want to participate in')}
          </Text>
        </Box>
        <Box mb={5}>
          <Text fontSize="sm">
            {t('Deposits are automatically processed within 1 to 3 minutes.')}
          </Text>
        </Box>
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          {plans.map((plan, index) => (
            <Button
              key={index}
              onClick={() => handlePlanSelection(plan)}
              bg={selectedPlan.amount === plan.amount ? '#33D1C9' : '#f0f0f0'}
              color={
                selectedPlan.amount === plan.amount ? '#ffffff' : '#000000'
              }
              _hover={{ bg: '#33D1C9' }}
            >
              {plan.amount}
            </Button>
          ))}
        </Grid>

        <Box
          mt={8}
          p={5}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          shadow="md"
          bg="white"
        >
          <Text>
            {t('Selected Plan')}: {selectedPlan.amount} USDT
          </Text>
          <Text>
            {t('Required Tokens')}:{' '}
            {new BigNumber(selectedPlan.amount).dividedBy(rate).toFixed(2, 1)}{' '}
            USDT
          </Text>
          <Text>
            {t('Personal Reward')}: {selectedPlan.dailyReward}
          </Text>
          <Text>
            {t('Maximum Reward Limit')}: {selectedPlan.maxReward}
          </Text>
        </Box>

        {/* <Box
          mt={4}
          p={5}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          shadow="md"
          bg="white"
        >
          {t('Wallet balance')}: {balance} USDT
        </Box> */}

        <Box
          mt={4}
          p={5}
          border="1px solid"
          borderColor="gray.200"
          borderRadius="md"
          shadow="md"
          bg="white"
        >
          <Checkbox
            isChecked={isChecked}
            onChange={(e) => setIsChecked(e.target.checked)}
            colorScheme="teal"
          >
            <Text>{t('The plan is determined by the exchange rate.')}</Text>
            <Text>{t('Do you agree with the above regulations?')}</Text>
          </Checkbox>
        </Box>

        <Button
          w={'100%'}
          mt={4}
          mb={6}
          size="lg"
          bg="#33D1C9"
          color="#ffffff"
          _hover={{ bg: '#2BA8A3' }}
          onClick={handleTokenTransfer}
          isLoading={isLoading} // 로딩 상태에 따라 버튼 활성화/비활성화
          spinner={<Spinner />} // 로딩 스피너 표시
          isDisabled={!isChecked} // 체크박스 체크 여부에 따라 버튼 비활성화
        >
          {t('Transfer Tokens')}
        </Button>
        {/* <Button mt={4} mb={6} w={'100%'}>
          일시 중지
        </Button> */}
      </DefaultLayout>

      <AlertDialog
        isOpen={isDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('Transaction Success')}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{t('Transaction ID')}</Text>
              <Text mt={4} wordBreak="break-all">
                {txId}
              </Text>
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="teal"
                onClick={() => {
                  onCopy()
                  toast({
                    title: t('Copied'),
                    description: t('Transaction ID copied to clipboard.'),
                    status: 'success',
                    duration: 1500,
                    isClosable: true
                  })
                }}
              >
                {t('Copy Transaction ID')}
              </Button>
              <Button ref={cancelRef} onClick={onClose} ml={3}>
                {t('Close')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <AlertDialog
        isOpen={isManualDialogOpen}
        leastDestructiveRef={cancelRef}
        onClose={onManualClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              {t('Manual Processing')}
            </AlertDialogHeader>

            <AlertDialogBody>
              <Text>{t('Please enter the transaction ID')}</Text>
              <Input
                mt={4}
                placeholder={t('Enter transaction ID')}
                value={manualTxId}
                onChange={(e) => setManualTxId(e.target.value)}
              />
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button
                colorScheme="teal"
                onClick={handleManualProcessing}
                isLoading={isManualProcessingLoading}
              >
                {t('Process')}
              </Button>
              <Button ref={cancelRef} onClick={onManualClose} ml={3}>
                {t('Cancel')}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  )
}

export default Deposit
