import React, { useState, useEffect } from 'react'
import { Box, Text, IconButton } from '@chakra-ui/react'
import { InfoIcon } from '@chakra-ui/icons'
import { useNavigate } from 'react-router-dom'
import { getNoticeList } from '../services/apiService' // API 호출 함수

const Announcements = () => {
  const [index, setIndex] = useState(0)
  const [announcements, setAnnouncements] = useState<
    { message: string; link: string }[]
  >([])
  const navigate = useNavigate() // react-router-dom의 useNavigate 사용

  useEffect(() => {
    // 공지사항 데이터를 가져오는 API 호출
    const fetchNotices = async () => {
      try {
        const { data: res } = await getNoticeList({ page: 1, offset: 5 })
        if (res.statusCode === 200) {
          // 공지사항 데이터로 announcements를 채움
          const fetchedAnnouncements = res.data.map((notice: any) => ({
            message: notice.title,
            link: `/notice/${notice.id}`
          }))
          setAnnouncements(fetchedAnnouncements)
        }
      } catch (error) {
        console.error('Failed to fetch notices:', error)
      }
    }

    fetchNotices() // API 호출

    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % announcements.length)
    }, 3000) // 3초마다 공지사항 변경

    return () => clearInterval(interval) // 컴포넌트 언마운트시 인터벌 정리
  }, [announcements.length]) // announcements 배열의 길이가 변경될 때마다 effect 실행

  // 공지사항을 클릭했을 때 상세 페이지로 이동
  const handleAnnouncementClick = (link: string) => {
    navigate(link) // useNavigate로 페이지 이동
  }

  return (
    <>
      {announcements.length !== 0 && (
        <>
          <Box
            maxW="md"
            mx="auto"
            height="30px" // 텍스트 컨테이너 높이
            overflow="hidden"
            position="relative"
            mt={-2}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              height={`${announcements.length * 30}px`} // 각 텍스트 높이에 맞춤
              transition="transform 0.5s ease-in-out"
              transform={`translateY(-${index * 30}px)`} // 각 공지사항 높이에 맞춰 이동
            >
              {announcements.map((announcement, idx) => (
                <Box
                  key={idx}
                  display="flex"
                  height="30px"
                  alignItems="center"
                  justifyContent="space-between"
                  px="4" // 공지사항 내부 여백 추가
                  cursor="pointer"
                  onClick={() => handleAnnouncementClick(announcement.link)} // 클릭 이벤트 설정
                >
                  <Text fontSize="sm">
                    {announcement.message.length > 25
                      ? `${announcement.message.substring(0, 25)}...`
                      : announcement.message}
                  </Text>
                  <IconButton
                    aria-label="상세보기"
                    icon={<InfoIcon />}
                    onClick={(e) => {
                      e.stopPropagation() // 부모 요소의 클릭 이벤트 막기
                      navigate('/notice')
                    }}
                    size="sm"
                    variant="ghost"
                  />
                </Box>
              ))}
            </Box>
          </Box>
        </>
      )}
    </>
  )
}

export default Announcements
