import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { getNoticeList } from '../services/apiService'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'

interface Notice {
  title: string
  content: string
  createdAt: string
}

const NoticeDialog: React.FC = () => {
  const [notice, setNotice] = useState<Notice | null>(null)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { t } = useTranslation()

  useEffect(() => {
    // 현재 날짜와 저장된 날짜를 비교하여 팝업을 보여줄지 결정
    const today = new Date().toLocaleDateString() // 오늘의 날짜를 문자열로 저장
    const hidePopupUntil = localStorage.getItem('hidePopupUntil')

    if (hidePopupUntil === today) {
      return // "오늘 다시 보지 않기"가 설정되어 있으면 팝업을 열지 않음
    }

    const fetchNotice = async () => {
      try {
        const { data: res } = await getNoticeList({
          page: 1,
          offset: 1,
          isPopup: 1
        })
        if (res.statusCode === 200 && res.data.length > 0) {
          const noticeData = res.data[0] // 첫 번째 공지사항만 가져옴
          setNotice({
            title: noticeData.title,
            content: noticeData.content,
            createdAt: noticeData.createdAt
          })
          onOpen() // 데이터를 가져온 후 Dialog를 염
        }
      } catch (error) {
        console.error('Error fetching notice', error)
      }
    }

    fetchNotice()
  }, [onOpen])

  // "오늘 다시 보지 않기" 버튼 클릭 처리
  const handleDontShowToday = () => {
    const today = new Date().toLocaleDateString() // 오늘의 날짜를 문자열로 저장
    localStorage.setItem('hidePopupUntil', today) // localStorage에 오늘 날짜 저장
    onClose() // 팝업 닫기
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx={3}>
          <ModalHeader>{t('Notice')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {notice ? (
              <Box>
                <Text fontWeight="bold">{notice.title}</Text>
                <Text
                  mt={4}
                  dangerouslySetInnerHTML={{ __html: notice.content }}
                />
                <Text mt={4} fontSize="sm" color="gray.500" textAlign={'right'}>
                  {format(new Date(notice.createdAt), 'yyyy.MM.dd HH:mm')}
                </Text>
              </Box>
            ) : (
              <Text>Loading...</Text>
            )}

            <Box>
              <Button
                width="full"
                onClick={onClose}
                bg="#33D1C9"
                color="#ffffff"
                size="lg"
                _hover={{ bg: '#2BA8A3' }}
                mt={5}
              >
                {t('Close')}
              </Button>
              <Button
                width="full"
                onClick={handleDontShowToday}
                bg="gray.500"
                color="#ffffff"
                size="lg"
                _hover={{ bg: 'gray.400' }}
                mt={2}
                mb={10}
              >
                {t("Don't show again today")}
              </Button>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default NoticeDialog
