import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  VStack,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Button
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import { getNoticeList } from '../services/apiService'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'

const NoticeList: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const [notices, setNotices] = useState<any[]>([]) // 공지사항 데이터를 저장할 상태
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const [page, setPage] = useState<number>(1) // 현재 페이지를 추적하는 상태
  const [hasMore, setHasMore] = useState<boolean>(true) // 더 가져올 데이터가 있는지 여부
  const { t } = useTranslation()
  const navigate = useNavigate() // react-router-dom의 useNavigate 사용

  const fetchNotices = async (currentPage: number) => {
    try {
      const { data: res } = await getNoticeList({ page: currentPage })
      if (res.statusCode === 200) {
        if (res.data.length > 0) {
          setNotices((prevNotices) => [...prevNotices, ...res.data]) // 이전 데이터에 새 데이터를 추가
        } else {
          setHasMore(false) // 더 이상 데이터가 없을 때
        }
      }
    } catch (error) {
      setError(t('Failed to load notices. Please try again later.'))
    } finally {
      setLoading(false) // 로딩 상태 종료
    }
  }

  useEffect(() => {
    setLoading(true) // 새로운 페이지 데이터를 불러오기 전 로딩 상태로 변경
    fetchNotices(page)
  }, [page]) // 페이지가 변경될 때만 호출되도록 설정

  const loadMoreNotices = () => {
    if (!loading && hasMore) {
      setPage((prevPage) => prevPage + 1) // 페이지 증가
    }
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={5}>
          <Heading as="h4" size="md">
            {t('Notice')}
          </Heading>
          <Text mt={0.5}>
            {t(
              'Check the latest notices and stay informed of important updates.'
            )}
          </Text>
        </Box>

        <Box>
          {loading && notices.length === 0 ? (
            <Spinner />
          ) : error ? (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          ) : notices.length === 0 ? (
            // <Text>{t('No notices available.')}</Text>
            <></>
          ) : (
            <VStack spacing={4} align="stretch">
              {notices.map((notice) => (
                <Box
                  key={notice.id}
                  p={5}
                  shadow="md"
                  borderWidth="1px"
                  bg={'white'}
                  onClick={() => {
                    navigate('/notice/' + notice.id)
                  }}
                >
                  <Heading fontSize="lg">{notice.title}</Heading>
                  <Text
                    mt={2}
                    fontSize="sm"
                    color="gray.500"
                    textAlign={'right'}
                  >
                    {format(new Date(notice.createdAt), 'yyyy.MM.dd HH:mm')}
                  </Text>
                </Box>
              ))}
            </VStack>
          )}

          {/* 더보기 버튼 */}
          {hasMore && !loading && (
            <Button
              width="full"
              onClick={loadMoreNotices}
              isLoading={loading}
              bg="#33D1C9"
              color="#ffffff"
              size="lg"
              _hover={{ bg: '#2BA8A3' }}
              mt={5}
              mb={10}
            >
              {t('Load More')}
            </Button>
          )}

          {loading && notices.length > 0 && <Spinner mt={4} />}
        </Box>
      </DefaultLayout>
    </>
  )
}

export default NoticeList
