import React, { useEffect, useState } from 'react'
import {
  Box,
  VStack,
  Heading,
  Text,
  Image,
  Spinner,
  useToast,
  Flex
} from '@chakra-ui/react'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import EasyLottie from '../components/EasyLottie'
import hotelAnimation from '../lotties/hotel.json'
import { getHotelList } from '../services/apiService'
import BigNumber from 'bignumber.js'
import { useNavigate } from 'react-router-dom'

interface Hotel {
  id: number
  name: string
  desc: string
  price: string
  star: number
  address: string
  mainImage: string | null
  subImage: string[]
}

const Hotel: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [hotels, setHotels] = useState<Hotel[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  // Fetch the hotel list on component mount
  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const { data: res } = await getHotelList()
        if (res.statusCode === 200) {
          setHotels(res.data)
        }
      } catch (error) {
        // error
      } finally {
        setLoading(false)
      }
    }
    fetchHotels()
  }, [toast])

  if (loading) {
    return (
      <DefaultLayout>
        <Box textAlign="center" mt={20}>
          <Spinner size="xl" />
        </Box>
      </DefaultLayout>
    )
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        {hotels.length === 0 ? (
          <>
            <Box pt={20}>
              <EasyLottie animationData={hotelAnimation} />
              <Box textAlign={'center'} mt={3}>
                <Text>{t('Currently preparing the product.')}</Text>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Text fontWeight={'bold'}>{t('Wave Partner Hotel')}</Text>
              <Text fontSize="xs" color="gray.600">
                {t(
                  'Enhance your travels with the Wave Project by adding a touch of special experiences and relaxation. Enjoy the comfort and charm of well-being and leisure.'
                )}
              </Text>
            </Box>

            <VStack spacing={5} mt={5} align="stretch" mb={20}>
              {hotels.length > 0 &&
                hotels.map((hotel) => (
                  <Box
                    key={hotel.id}
                    bg={'white'}
                    borderWidth="1px"
                    borderRadius="lg"
                    overflow="hidden"
                    onClick={() => {
                      navigate('/hotel/' + hotel.id)
                    }}
                  >
                    {hotel.mainImage && (
                      <Image
                        src={hotel.mainImage}
                        alt={hotel.name}
                        width="100%"
                        height="200px"
                        objectFit="cover"
                      />
                    )}
                    <Box px={3} py={5}>
                      <Heading size="md" mt={0}>
                        {hotel.name}{' '}
                        {Array(hotel.star)
                          .fill('')
                          .map((_, index) => (
                            <span key={index}>⭐</span>
                          ))}
                      </Heading>
                      <Box maxW="100%" overflow="hidden">
                        <Text
                          mt={2}
                          noOfLines={2}
                          sx={{
                            display: '-webkit-box',
                            WebkitLineClamp: 2,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                          }}
                        >
                          {hotel.desc}
                        </Text>
                      </Box>
                      <Text
                        mt={2}
                        fontWeight="bold"
                        textAlign={'right'}
                        color="#33D1C9"
                      >
                        {new BigNumber(hotel.price).toFixed(2, 1)} WVP
                      </Text>
                    </Box>
                  </Box>
                ))}
            </VStack>
          </>
        )}
      </DefaultLayout>
    </>
  )
}

export default Hotel
