import React, { useEffect, useState } from 'react'
import {
  Box,
  Heading,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Button
} from '@chakra-ui/react'
import { useNavigate, useParams } from 'react-router-dom' // useParams로 URL 파라미터를 얻음
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import CheckAuth from '../components/layouts/CheckAuth'
import { format } from 'date-fns'
import { getNotice } from '../services/apiService' // API 서비스에서 getNotice 가져옴

const Notice: React.FC = () => {
  const { id } = useParams<{ id: string }>() // URL에서 id 파라미터 추출
  const { t } = useTranslation()
  const [notice, setNotice] = useState<any>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string | null>(null)
  const navigate = useNavigate() // react-router-dom의 useNavigate 사용

  useEffect(() => {
    const fetchNotice = async () => {
      try {
        const { data: res } = await getNotice({ id: Number(id) }) // 공지사항 ID로 API 호출
        if (res.statusCode === 200) {
          setNotice(res.data)
        } else {
          setError(t('Failed to load notice.'))
        }
      } catch (error) {
        setError(t('Failed to load notice. Please try again later.'))
      } finally {
        setLoading(false)
      }
    }

    fetchNotice() // 공지사항 데이터 가져오기
  }, [id, t])

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={5} textAlign={'right'}>
          <Button
            onClick={() => {
              navigate('/notice')
            }}
          >
            {t('Back to Notices')}
          </Button>
        </Box>

        <Box>
          {loading ? (
            <Spinner />
          ) : error ? (
            <Alert status="error">
              <AlertIcon />
              {error}
            </Alert>
          ) : notice ? (
            <Box p={5} borderWidth="1px" bg="white">
              <Heading fontSize="xl">{notice.title}</Heading>
              <Text
                mt={4}
                dangerouslySetInnerHTML={{ __html: notice.content }}
              />
              <Text mt={4} fontSize="sm" color="gray.500" textAlign={'right'}>
                {format(new Date(notice.createdAt), 'yyyy.MM.dd HH:mm')}
              </Text>
            </Box>
          ) : (
            <Text>{t('No notice found.')}</Text>
          )}
        </Box>
      </DefaultLayout>
    </>
  )
}

export default Notice
