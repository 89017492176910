// src/utils/authUtils.ts

import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import { getUser } from '../services/apiService'

export const useUpdateAuthState = () => {
  const [auth, setAuth] = useRecoilState(authState)

  const updateAuthState = async () => {
    try {
      const response = await getUser()
      if (response?.data?.data) {
        // NOTE setAuth수정
        const {
          walletAddress,
          username,
          tokenBalance,
          stakingBalance,
          stakingPercent,
          limitReward,
          autoStaking,
          code,
          userLevel,
          wvpLock,

          rewardTeamSum,
          rewardVolumeSum,
          rewardWaveSum,
          rewardTotalSum,

          valueSup,
          valueRef,
          listSupLength,
          listRefLength
        } = response.data.data
        setAuth({
          walletAddress: walletAddress || null,
          username: username || '',
          tokenBalance: tokenBalance || '0.00000000',
          stakingBalance: stakingBalance || '0.00000000',
          stakingPercent: stakingPercent,
          limitReward: limitReward,
          autoStaking: autoStaking,
          code: code || '',
          userLevel: 0,
          wvpLock: wvpLock || null,

          rewardTeamSum: rewardTeamSum,
          rewardVolumeSum: rewardVolumeSum,
          rewardWaveSum: rewardWaveSum,
          rewardTotalSum: rewardTotalSum,

          valueSup: valueSup,
          valueRef: valueRef,
          listSupLength: listSupLength,
          listRefLength: listRefLength
        })
      }
    } catch (error) {
      console.error('Failed to update auth state', error)
    }
  }

  return updateAuthState
}
