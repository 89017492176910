import React, { useState } from 'react'
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Heading,
  useToast,
  Text
} from '@chakra-ui/react'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { useTranslation } from 'react-i18next'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import CheckAuth from '../components/layouts/CheckAuth'
import { changePassword } from '../services/apiService'

const ChangePassword: React.FC = () => {
  const [auth] = useRecoilState(authState)
  const toast = useToast()
  const { t } = useTranslation()

  // Validation schema using Yup
  const validationSchema = Yup.object({
    currentPassword: Yup.string().required(t('Current password is required')),
    newPassword: Yup.string()
      .min(8, t('New password must be at least 8 characters'))
      .required(t('New password is required')),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], t('Passwords must match'))
      .required(t('Confirm new password is required'))
  })

  const initialValues = {
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  }

  const handleSubmit = async (values: typeof initialValues) => {
    try {
      const { data: res } = await changePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword
      })
      if (res.statusCode === 200) {
        toast({
          title: t('Success'),
          description: t('Your password has been changed successfully.'),
          status: 'success',
          duration: 1500,
          isClosable: true
        })

        // 폼초기화
        values.currentPassword = ''
        values.newPassword = ''
        values.confirmNewPassword = ''
      }
    } catch (err: any) {
      toast({
        title: t('Fail'),
        description: t(err.response?.data?.message),
        status: 'error',
        duration: 1500,
        isClosable: true
      })
    }
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={5}>
          <Heading as="h4" size="md">
            {t('Change password')}
          </Heading>
          <Text mt={0.5}>{t('You can change your password.')}</Text>
        </Box>

        <Box
          maxW="md"
          mx="auto"
          mt={6}
          p={6}
          borderWidth={1}
          borderRadius="lg"
          bg={'white'}
        >
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {() => (
              <Form>
                <VStack spacing={4}>
                  <FormControl id="currentPassword">
                    <FormLabel>{t('Current Password')}</FormLabel>
                    <Field name="currentPassword">
                      {({ field }: { field: any }) => (
                        <Input type="password" {...field} />
                      )}
                    </Field>
                    <ErrorMessage name="currentPassword">
                      {(msg) => <Text color="red.500">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl id="newPassword">
                    <FormLabel>{t('New Password')}</FormLabel>
                    <Field name="newPassword">
                      {({ field }: { field: any }) => (
                        <Input type="password" {...field} />
                      )}
                    </Field>
                    <ErrorMessage name="newPassword">
                      {(msg) => <Text color="red.500">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl id="confirmNewPassword">
                    <FormLabel>{t('Confirm New Password')}</FormLabel>
                    <Field name="confirmNewPassword">
                      {({ field }: { field: any }) => (
                        <Input type="password" {...field} />
                      )}
                    </Field>
                    <ErrorMessage name="confirmNewPassword">
                      {(msg) => <Text color="red.500">{msg}</Text>}
                    </ErrorMessage>
                  </FormControl>
                  <Button
                    type="submit"
                    bg="#33D1C9"
                    color="#ffffff"
                    size="lg"
                    _hover={{ bg: '#2BA8A3' }}
                    width="full"
                  >
                    {t('Change password')}
                  </Button>
                </VStack>
              </Form>
            )}
          </Formik>
        </Box>
      </DefaultLayout>
    </>
  )
}

export default ChangePassword
