// frontend/src/pages/Withdrawal.tsx
import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  Box,
  Button,
  Card,
  CardBody,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Icon,
  Input,
  Stat,
  StatLabel,
  StatNumber,
  Text,
  useToast,
  VStack
} from '@chakra-ui/react'
import DefaultLayout from '../components/layouts/DefaultLayout'
import { InfoIcon } from '@chakra-ui/icons'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import CheckAuth from '../components/layouts/CheckAuth'
import { useRecoilState } from 'recoil'
import { authState } from '../atoms/auth'
import BigNumber from 'bignumber.js'
import { withdrawal } from '../services/apiService'
import { useUpdateAuthState } from '../utils/authUtils'
import { rateState } from '../atoms/rate'
import { intervalToDuration, isAfter, isBefore } from 'date-fns'

interface FormValues {
  amount: number | ''
}

const Withdrawal: React.FC = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const toast = useToast()
  const [auth, setAuth] = useRecoilState(authState)
  const [rate, setRate] = useRecoilState(rateState)
  const updateAuthState = useUpdateAuthState()

  const formik = useFormik<FormValues>({
    initialValues: {
      amount: ''
    },
    validationSchema: Yup.object({
      amount: Yup.number()
        .required(t('Please enter the amount you wish to withdraw.'))
        .min(10, t('The minimum withdrawal amount is 10 WVP.'))
        .max(
          new BigNumber(auth.tokenBalance).toNumber(),
          t('The amount exceeds the available tokens.')
        )
        .test(
          'check-amount',
          t('The amount exceeds the available tokens.'),
          function (value: any) {
            console.log('value => ', value)

            if (value !== undefined && value !== '') {
              const fee = value * 0.05
              return value + fee <= new BigNumber(auth.tokenBalance).toNumber()
            }
            return true
          }
        )
    }),
    onSubmit: async (values) => {
      console.log('values => ', values)

      try {
        const { data: res } = await withdrawal({
          tokenAmount: new BigNumber(values.amount).toString()
        })
        if (res.statusCode === 200) {
          // toast
          toast({
            title: t('Success'),
            description: t(res.message),
            status: 'success',
            duration: 1500,
            isClosable: true
          })

          // Update auth state after successful withdrawal
          await updateAuthState()

          // 폼초기화
          formik.resetForm()
        }
      } catch (err: any) {
        toast({
          title: t('Fail'),
          description: t(err.response.data.message),
          status: 'error',
          duration: 1500,
          isClosable: true
        })
      }
    }
  })

  const withdrawalFee = formik.values.amount ? formik.values.amount * 0.05 : 0
  const requiredAmount = formik.values.amount
    ? formik.values.amount + withdrawalFee
    : 0

  const formatLockupTime = (wvpLock: string | null): string => {
    // wvpLock이 undefined나 null인 경우 처리
    if (!wvpLock) {
      return '-'
    }

    // wvpLock을 ISO 형식의 날짜 문자열로 간주하고 Date 객체로 변환
    const lockupEndDate = new Date(wvpLock)

    // 현재 시간을 구하고 KST로 변환
    const now = new Date()

    // lockupEndDate와 현재 시간의 차이를 구함 (밀리초)
    const duration = intervalToDuration({ start: now, end: lockupEndDate })

    // 포맷팅된 시간 계산
    let formattedTime = ''

    // 각 값에 기본값을 설정하여 undefined 방지
    const days = duration.days || 0
    const hours = duration.hours || 0
    const minutes = duration.minutes || 0

    if (days > 0) {
      formattedTime = `${days} ${t('Day')} ${hours} ${t('Hour')} ${minutes} ${t(
        'Minute'
      )}`
    } else if (hours > 0) {
      formattedTime = `${hours} ${t('Hour')} ${minutes} ${t('Minute')}`
    } else {
      formattedTime = `${minutes} ${t('Minute')}`
    }

    return formattedTime
  }

  // 락업 확인
  // 락업: true, 정상: false
  const isLockupExpired = (wvpLock: string | null): boolean => {
    // wvpLock이 undefined나 null인 경우 처리
    if (!wvpLock) {
      return false
    }

    // wvpLock을 ISO 형식의 날짜 문자열로 간주하고 Date 객체로 변환
    const lockupEndDate = new Date(wvpLock)

    // 현재 시간
    const now = new Date()

    // lockupEndDate가 현재 시간보다 이전인지 확인
    return isAfter(lockupEndDate, now)
  }

  const handleMaxClick = () => {
    const tokenBalance = new BigNumber(auth.tokenBalance)
    const maxWithdrawAmount = tokenBalance.div(1.05).toFixed(0) // Subtracting fee
    formik.setFieldValue('amount', parseFloat(maxWithdrawAmount))
  }

  return (
    <>
      <CheckAuth />
      <DefaultLayout>
        <Box mb={2}>
          <Heading as="h4" size="md">
            {t('Withdrawal')}
          </Heading>
          <Text mt={0.5}>
            {t('Thank you for participating in the project')}
          </Text>
        </Box>

        <VStack spacing={4} align="stretch">
          <StatBox
            label={t('Available tokens for withdrawal')}
            value={`${new BigNumber(auth.tokenBalance).toFixed(4, 1)} WVP`}
            tooltipContent={t(
              'Tokens that are currently withdrawable and not staked.'
            )}
          />

          <Card>
            <CardBody>
              <Box
                as="form"
                onSubmit={formik.handleSubmit}
                w="100%"
                maxW="400px"
              >
                <FormControl
                  id="amount"
                  isInvalid={formik.touched.amount && !!formik.errors.amount}
                >
                  <FormLabel>{t('Amount to Withdraw')}</FormLabel>
                  <Flex>
                    <Input
                      name="amount"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.amount}
                    />
                    <Button ml={2} onClick={handleMaxClick}>
                      {t('Max')}
                    </Button>
                  </Flex>
                  <FormErrorMessage>{formik.errors.amount}</FormErrorMessage>
                </FormControl>
                <Box mt={4}>
                  <Text>
                    {t('Withdrawal Fee')}: {withdrawalFee.toFixed(4)} WVP
                  </Text>
                  <Text mt={2} fontWeight="bold">
                    {t('Required Amount')}: {requiredAmount.toFixed(4)} WVP
                  </Text>
                </Box>
              </Box>
            </CardBody>
          </Card>
        </VStack>
        {isLockupExpired(auth?.wvpLock) ? (
          <>
            <Box mt={4} textAlign={'center'}>
              <Text color={'red'}>{t('Token Lockup')}</Text>
              <Text color={'red'} fontWeight={'bold'} fontSize={'large'}>
                {formatLockupTime(auth?.wvpLock)}
              </Text>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <Button
                w={'100%'}
                mt={4}
                size="lg"
                bg="#33D1C9"
                color="#ffffff"
                _hover={{ bg: '#2BA8A3' }}
                onClick={formik.submitForm}
              >
                {t('Submit Withdrawal')}
              </Button>
              {/* <Card mt={5}>
                <CardBody>
                  <Text>
                    현재 출금 정지는 회원님들의 자산 보호를 위한 조치이며
                    마이그레이션 후 출금 가능합니다.
                  </Text>
                </CardBody>
              </Card>
              <Button mt={4} mb={6} w={'100%'}>
                일시 중지
              </Button> */}
            </Box>
          </>
        )}
      </DefaultLayout>
    </>
  )
}

const StatBox = ({ label, value, tooltipContent }: any) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [auth, setAuth] = useRecoilState(authState)
  const [rate, setRate] = useRecoilState(rateState)

  const handleToggleTooltip = () => {
    setShowTooltip(!showTooltip)
  }

  return (
    <Card>
      <CardBody>
        <Stat>
          <StatLabel>
            <Flex align="center">
              {label}
              {tooltipContent && (
                <Box ml={2} onClick={handleToggleTooltip}>
                  <Icon
                    as={InfoIcon}
                    color="gray.500"
                    w={4}
                    h={4}
                    cursor="pointer"
                  />
                  {showTooltip && (
                    <Box
                      mt={2}
                      p={2}
                      bg="gray.700"
                      color="white"
                      borderRadius="md"
                      position="absolute"
                      zIndex={1}
                      maxW="xs"
                    >
                      {tooltipContent}
                    </Box>
                  )}
                </Box>
              )}
            </Flex>
          </StatLabel>
          <StatNumber>{value}</StatNumber>
          <Box>
            ≈{' '}
            {new BigNumber(auth.tokenBalance).multipliedBy(rate).toFixed(2, 1)}{' '}
            {'USDT'}
          </Box>
        </Stat>
      </CardBody>
    </Card>
  )
}

export default Withdrawal
